<template>
  <div class="space-y-4">
    <article>
      <section class="relative z-0 rounded-md grid sm:grid-cols-1 md:grid-cols-4">
        <asom-button
          v-if="canConfigureWayfinding"
          variant="primary"
          outline
          rounded="none"
          @click="$router.push({ name: 'Wayfinding Configure Map' })"
          icon="file-document"
          text="Configure Map"
        >
        </asom-button>
      </section>
    </article>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div class="w-full text-center" v-else-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <asom-card v-else title="Go-to Maps">
      <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
        <asom-form-field label="Landmark"
          description="Type to Search for Landmark"
        >
          <asom-input-text v-model="search" />
        </asom-form-field>
        <asom-form-field label="MRT Station"
        >
          <asom-input-select
            :objectModelValue="false"
            v-model="stationId"
            :options="stationOptions"
            placeholder="Select MRT station"
          />
        </asom-form-field>
        <div class="col-span-2">
          <asom-button v-if="!emptyFilter" @click="viewInGoogleMap" size='lg' text="Search using Google Map if you cannot find matching landmark"></asom-button>
        </div>
        <div class="col-span-2" v-if="emptyFilter">Start to search by keywords or select a station to see matching results.</div>
        <div class="col-span-2" v-else-if="filteredData.length === 0">No result found.</div>
        <div v-else class="space-y-3 w-full col-span-2">
          <tourist-map-card v-for="map in filteredData" :key="map.touristMapId"
            :stationName="map.stationName"
            :fileId="map.attachmentId"
            :fileName="map.attachmentFileName"
            :landMarkData="map.landMarkData"
            :search="searchInLowerCase"
          />
        </div>
      </div>
      
    </asom-card>
  </div>
</template>
<script>
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import { mapGetters } from 'vuex';
import {
  searchMap,
} from '@/services/tourist.service';
import TouristMapCard from './TouristMapCard.vue'
import { GOOGLE_MAP } from '../../constants/apis';

export default {
  components: {
    TouristMapCard
  },
  data() {
    return {
      isLoading: false,
      error: null,
      stationId: null,
      search: '',
      data: [],
    }
  },
  mounted() {
    this.loadStationTouristMaps();
  },
  computed: {
    ...mapGetters({
      stationOptions: 'smrtResource/wayfindingStationOptions',
      rosterStationName: 'selectedStation/name',
      baseStationName: 'auth/userStationName',
      canConfigureWayfinding: 'auth/canConfigureWayfinding',
    }),
    emptyFilter() {
      return isEmpty(this.searchInLowerCase) && isEmpty(this.stationId);
    },
    googleStationName() {
      return this.rosterStationName || this.baseStationName;
    },
    selectedStationName() {
      const option = this.stationOptions.find(o => o.value === this.stationId);
      return get(option, 'name', null);
    },
    searchInLowerCase() {
      return this.search.trim().toLowerCase();
    },
    filteredData() {
      return this.data.filter(({
        landMarkData,
        stationId,
      }) => {
        return (isEmpty(this.stationId) || stationId === this.stationId) &&
          (isEmpty(this.searchInLowerCase) || isEmpty(landMarkData) || landMarkData.toLowerCase().includes(this.searchInLowerCase));
      })
    }
  },
  methods: {
    async loadStationTouristMaps() {
      this.error = null;
      this.isLoading = true;
      const resp = await searchMap();
      if (resp.success) {
        this.data = get(resp, 'payload.list', []);
      } else {
        this.error = resp.payload;
        this.$scrollTop();
      }
      this.isLoading = false;
    },
    viewInGoogleMap() {
      let url = GOOGLE_MAP;
      console.log(this.googleStationName);
      if (isEmpty(this.googleStationName)) {
        url = `${GOOGLE_MAP}/search/${this.searchInLowerCase}?country=sg`
      } else {
        url = `${GOOGLE_MAP}/dir/${this.googleStationName}+MRT/${this.searchInLowerCase}`;
      }
      window.open(url); 
    }
  }
}
</script>