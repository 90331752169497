<template>
  <asom-card class="w-full">
    <div class="space-y-2">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{stationName}}
        <asom-badge v-if="landMarkData">{{landMarks.length}} Land Marks</asom-badge>
      </h3>
      <div class="text-sm space-x-2">
        <span>{{fileName}}</span>
        <asom-link class="space-x-1 content-center" @click="viewMap()">
          <span>View Map</span>
        </asom-link>
      </div>
      <div class="-ml-2 w-full" v-if="landMarkData">
        <asom-badge class="ml-2 my-1" v-for="(l, i) in landMarks" :key="i" :variant="landMarkVariant(l)">{{l.toUpperCase()}}</asom-badge>
      </div>
    </div>
  </asom-card>
</template>
<script>
import isEmpty from 'lodash.isempty';
import { downloadFile } from '../../services/file.service';

export default {
  props: {
    stationName: {
      type: String,
    },
    fileId: {
      type: String,
    },
    fileName: {
      type: String,
    },
    landMarkData: {
      type: String
    },
    search: {
      type: String,
    }
  },
  computed: {
    landMarks() {
      return !this.landMarkData ? [] : this.landMarkData.split("; ");
    }
  },
  methods: {
    landMarkVariant(str) {
      if (isEmpty(this.search)) return 'secondary'
      return str.toLowerCase().includes(this.search) ? 'primary' : 'secondary';
    },
    async viewMap() {
      this.error = null;
      
      const response = await downloadFile({ fileId: this.fileId });
      if (response) {
        var file = new Blob([response.data], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
      else {
        this.error = "Failed to open file";
        this.$scrollTop();
      }
    },
  }
}
</script>